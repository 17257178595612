<template>
  <div class="body-content">
    <Loader v-if="is_loading" style="display: flex; align-items: center; justify-content: center" v-bind:logo="loaderLogo" ></Loader>
    <div class="row mb-10">
      <div class="col-xl-12 col-md-12 col-sm-12 mb-3 ddd">
        <p class="table-head last_refreshed" style="font-size: 23px;">         
          <router-link to="/">
              <i class="fa fa-home mr-2" style="font-size: 30px; color: #000;"></i>
          </router-link>
          <span class="material-icons" style="margin-left: 0px !important;position: relative;top: 4px;margin-right: 9px;font-size: 21px;">arrow_forward_ios</span>Profile</p>
    		
        <!-- <router-link
          to="/client-conversation"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <a :href="href" class="menu-link">
            <span class="menu-text">Last update date</span>
          </a>
        </router-link> -->
		
		<b-modal id="modal-1" title="Performance Overview">
			<Modal/>
          	<!-- <p >Content goes here...</p> -->
        </b-modal>
        <!-- <span>
          <button
            style="font-size: 1.3rem; margin-top: 0px"
            type="button"
            class="fa fa-redo last_refreshed mx-2"
            @click="refreshed_data"
          ></button>
        </span> -->
        <span class="last_seen">
          <!-- <span> Last Refreshed : {{ prettyTime }} ago</span> -->

          <!-- <span class="last_refreshed">Refresh Page </span> -->
        </span>
      </div>
      <div class="col-xl-12 pt-0 profileInfo">
        <div class="inf-table pt-12 px-md-12 px-0">
			<!-- <div class="row mx-0">
				<div class="col-12">
					<div class="export-csv-btn">
						<label for="">Last upadated 3 hours ago</label>
						<button>Export CSV</button>
					</div>
				</div>
			</div> -->
			<!-- <span class="fa fa-download export_file mr-md-0 mr-2"  @click="DownloadFileAndFetch" ></span> -->
          <div class="tabs py-6 px-md-0 px-2">
            <!-- <div cols="6" class="text-capitalize text-left my-0 py-0">
              <p class="table-head table_res_head my-0 py-0">
                {{ selected_tab }}
              </p>
            </div> -->
            <v-spacer class="table_res_head"></v-spacer>
            <v-btn-toggle
              class="flex-wrap"
              v-model="selected_tab"
              active-class="tabs_parent"
              mandatory
              id="parent"
              group
            >
              <v-btn
                value="User Details"
                class="text-capitalize toggle_buttons"
                active-class="tab_buttons"
                @click="selected_tab = 'User Details'"
              >
                User Details
              </v-btn>
              <v-btn
                value="Bank Details"
                class="text-capitalize toggle_buttons"
                active-class="tab_buttons"
                @click="selected_tab = 'Bank Details'"
              >
               Bank Details 
              </v-btn>
              <!-- <v-btn
                value="affliateManagerDetails"
                class="text-capitalize toggle_buttons"
                active-class="tab_buttons"
                @click="selected_tab = 'affliateManagerDetails'"
              >
                Affiliate Manager Details
              </v-btn>
              <v-btn
                width="76.22px"
                value="referralDetails"
                class="text-capitalize toggle_buttons"
                active-class="tab_buttons"
                @click="selected_tab = 'referralDetails'"
              >
                Referral Details
              </v-btn> -->
              <!-- <v-btn
                width="76.22px"
                value="changePassword"
                class="text-capitalize toggle_buttons"
                active-class="tab_buttons"
                @click="selected_tab = 'changePassword'"
              >
               Change Password
              </v-btn> -->
            </v-btn-toggle>
          </div>
          <div class="data_grid mt-20" v-if="selected_tab == 'User Details'">
              <TabLoader v-if="is_user_loading" style="
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 100%;
                min-height: 300px;" v-bind:logo="loaderLogo" >
              </TabLoader>
              <div v-else>
                <div class="table-div" :class="selected_tab == 'User Details' ? 'userdetails' : ''">
                        <b-form class="form">
                          <div class="col-md-12">
                            <div role="alert" v-if="errorUser && !successUser" :class="{ 'show': error }" class="alert fade alert-danger d-flex align-items-center pl-3" style="height:36px;margin-bottom:10px;">
                                <span>{{ this.errorUserMsg }}</span>
                            </div>
                            <div role="alert" v-if="successUser" :class="{ 'show': successUser }" class="alert fade alert-success d-flex align-items-center pl-3" style="height:36px;margin-bottom:10px;">
                                <span>User Details Updated Successfully</span>
                            </div>
                            <div class="row">
                              <div class="col-md-6">
                                  <b-form-group label="User Name">
                                    <input type="text" class="form-control form-control-solid h-auto py-5 px-6" v-model="userDetails.company">
                                    <p v-if="!userDetails.company && !fetchUser" style="color:red;">Username is Required</p>
                                  </b-form-group>
                                  <b-form-group id="example-input-group-1" label="Email" label-for="example-input-1">
                                    <b-form-input placeholder="Email" readonly class="form-control form-control-solid h-auto py-5 px-6"  aria-describedby="input-1-live-feedback" v-model="userDetails.email"></b-form-input>
                                  </b-form-group>
                                  <b-form-group id="example-input-group-1" label="Phone Number" label-for="example-input-1">
                                    <b-form-input placeholder="Phone Number" class="form-control form-control-solid h-auto py-5 px-6"  aria-describedby="input-1-live-feedback" v-model="userDetails.phone"></b-form-input>
                                    <p v-if="!userDetails.phone &&  !fetchUser" style="color:red;position: absolute;margin-top: 91px;">Phone is Required</p>
                                  </b-form-group>

                                  <b-form-group label="Address" label-for="example-input-1">
                                    <b-form-textarea id="textarea" placeholder="Address Line 1" rows="6" max-rows="6" class="form-control form-control-solid" v-model="fullAddress" disabled></b-form-textarea>
                                    <b-button v-b-modal.address class="pencil-btn" @click="modalShow = !modalShow">
                                      <!-- <i class="fa-regular fas fa-pencil"></i> -->
                                      <img src="media/images/pencil.png" alt="">
                                    </b-button>
                                    <p v-if="!userDetails.address1 &&  !fetchUser" style="color:red;">Address1 is Required</p>
                                    <p v-if="!userDetails.city &&  !fetchUser" style="color:red;">City is Required</p>
                                  </b-form-group>
                                  <b-modal id="address" title="Address" v-model="modalShow">
                                    <div class="row">
                                      <div class="col-md-12">
                                        <b-form-group label="Address1" label-for="example-input-1">
                                          <b-form-input placeholder="Address1" class="form-control form-control-solid h-auto py-5 px-6"  aria-describedby="input-1-live-feedback" v-model="userDetails.address1"></b-form-input>
                                        </b-form-group>
                                        <b-form-group label="Country" label-for="example-input-1">
                                          <b-form-select class="mb-3 form-control-solid" v-model="userDetails.country" @change="getCityList()">
                                            <b-form-select-option disabled value='None'> Please select Country</b-form-select-option>
                                            <b-form-select-option v-for="(country) in countryList" :value="country.code" :key="country.index">{{ country?country.name:'' }}</b-form-select-option>
                                          </b-form-select>
                                        </b-form-group>
                                        <b-form-group label="City" label-for="example-input-1">
                                          <b-form-select  class="mb-3 form-control-solid" v-model="userDetails.city">
                                            <b-form-select-option disabled value='None'>Please select City</b-form-select-option>
                                            <b-form-select-option v-for="(city) in cityList" :value="city.name" :key="city.index">{{ city?city.name:'' }}</b-form-select-option>
                                          </b-form-select>
                                        </b-form-group>
                                      </div>
                                  </div>
                                    <template #modal-footer="{ ok }">
                                      <div class="filterButtonGroup">
                                        <b-button class="modal-btn btn modal-btn btn-light" variant="light" @click="ok()">
                                          Cancel
                                        </b-button>
                                        <b-button class="modal-btn btn btn-primary" variant="dark" @click="getFullAddress()">
                                          OK
                                        </b-button>
                                      </div>
                                    </template>
                                  </b-modal>
                              </div>
                              <div class="col-md-6">
                                  <b-form-group label="I'm a" label-for="example-input-1">
                                    <b-form-select class="mb-3" v-model="userDetails.type">
                                      <b-form-select-option disabled value='None'>Choose Option</b-form-select-option>
                                      <b-form-select-option value="agency" >Agency</b-form-select-option>
                                      <b-form-select-option value="affiliate-marketer">Affiliate Marketer</b-form-select-option>
                                      <b-form-select-option value="business-owner">Business Owner</b-form-select-option>
                                      <b-form-select-option value="dropship-marketer">Dropship Marketer</b-form-select-option>
                                      <b-form-select-option value="influencer">Influencer</b-form-select-option>
                                      <b-form-select-option value="other">Other</b-form-select-option>
                                    </b-form-select>
                                  </b-form-group>
                                  <!-- <b-form-group label="How many years of Affiliate /Coupon promotion experience do you have ?" label-for="example-input-1">
                                    <b-form-select class="mb-3" v-model="userDetails.experience_no">
                                      <b-form-select-option>Choose Option</b-form-select-option>
                                      <b-form-select-option value="no-experience">No experience</b-form-select-option>
                                      <b-form-select-option value="0-1">0-1 years</b-form-select-option>
                                      <b-form-select-option value="1-2">1-2 years</b-form-select-option>
                                      <b-form-select-option value="2-3">2-3 years</b-form-select-option>
                                      <b-form-select-option value="3+">3+ years</b-form-select-option>
                                    </b-form-select>
                                  </b-form-group> -->
                                  <!-- <b-form-group label="Which type of experience do you have ?" label-for="example-input-1">
                                    <b-form-select class="mb-3" v-model="userDetails.experience_type">
                                      <b-form-select-option>Choose Option</b-form-select-option>
                                      <b-form-select-option value="coupon-promotion">Coupon Promotion</b-form-select-option>
                                      <b-form-select-option value="product-promotion">Product Promotion</b-form-select-option>
                                      <b-form-select-option value="brand-promotion">Brand Promotion</b-form-select-option>
                                    </b-form-select>
                                  </b-form-group>                         -->
                                  <!-- <b-form-group label="ID proof" label-for="example-input-1"> -->
                                    <!-- <b-form-input placeholder="ID proof" type="text" class="mb-3" v-model="idProofInput"></b-form-input> -->
                                    <div class="d-flex justify-content-between">
                                      <a class="p-btn-terms cursor-pointer" @click="handleOnTerms">
                                            Click here to download terms and conditions
                                      </a>  
                                      <!-- <div class="p-upload-file">
                                        <label  v-if="!fileInObj" for="uploadFile" class="p-upload-file-btn">
                                          Upload File
                                        </label>
                                          <div v-else>
                                          <div class="imageClearDiv">
                                            <svg @click="clearFileFun" xmlns="http://www.w3.org/2000/svg" style="background: #ff6633;border-radius: 20px;" width="22" height="22" fill="white" class="bi bi-x" viewBox="0 0 16 16">
                                              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                            </svg>
                                          </div>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" class="bi bi-file-check" viewBox="0 0 16 16">
                                            <path d="M10.854 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 8.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                                            <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z"/>
                                          </svg>
                                          </div>
                                        <input id="uploadFile" style="visibility:hidden;" type="file" @change="handleOnUploadFile($event)">
                                      </div> -->
                                      
                                      <vue-html2pdf
                                          :show-layout="false"
                                          :float-layout="true"
                                          :enable-download="true"
                                          :preview-modal="false"
                                          :paginate-elements-by-height="1400"
                                          filename="Terms and condition"
                                          :pdf-quality="2"
                                          :manual-pagination="true"
                                          pdf-format="a4"  
                                          pdf-content-width="100%"              
                                          ref="html2Pdf"
                                          pdf-content-widt="700px"
                                          @progress="onProgress($event)"
                                      >
                                          <section slot="pdf-content">
                                            <terms-and-condition></terms-and-condition>
                                          </section>
                                      </vue-html2pdf>
                                    </div>
                                  <!-- </b-form-group>                         -->
                            </div>
                            </div>
                          </div>
                            <!-- <div class="input-group btns">
                              <button type="button" class="cancel">Cancel</button>
                              <button type="submit" class="save" @click="validateUserDetails" :disabled='isLoading'>Save</button>
                            </div> -->
                        </b-form>

                        <div class="input-group btns">
                          <button @click='validateUserDetails' class="save">Save</button>
                        </div>
                      </div>
              </div>
		      </div>
		     <div class="data_grid mt-20" v-if="selected_tab == 'Bank Details'">
          <TabLoader v-if="is_bank_loading" style="
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              width: 100%;
              min-height: 300px;" v-bind:logo="loaderLogo" ></TabLoader>
          <div v-else>
  
             <div class="table-div" :class="selected_tab == 'Bank Details' ? 'bankdetails' : ''">
              <form class="form" @submit.prevent="validateBankDetails">
                <div class="col-md-12">
                  <div role="alert" v-if="errorBank && !successBank" :class="{ 'show': error }" class="alert fade alert-danger d-flex align-items-center pl-3" style="height:36px;margin-bottom:10px;">
                      <span>{{ this.errorBankMsg }}</span>
                  </div>
                  <div role="alert" v-if="successBank" :class="{ 'show': successBank }" class="alert fade alert-success d-flex align-items-center pl-3" style="height:36px;margin-bottom:10px;">
                      <span>Bank Details Updated Successfully</span>
                  </div>
                   <div class="row">
                     <div class="col-md-6">
                        <div class="form-group">
                            <label>Beneficiary Full Name</label>
                            <input placeholder="Beneficiary Full Name" class="form-control form-control-solid h-auto py-5 px-6" v-model="bankDetails.beneficiary_name" @input="isLetter($event,'beneficiary_name')">
                            <span v-if="errors.beneficiary_name" class="text-danger">You can't add arabic letters or any symbols!</span>
                        </div>
                        <div class="form-group">
                            <label>Account Number</label>
                            <input placeholder="Account Number" class="form-control form-control-solid h-auto py-5 px-6" v-model="bankDetails.account_number" @input="isLetter1($event,'account_number')">
                            <p v-if="!bankDetails.account_number &&  !fetchBank" style="color:red;">Account no is required</p>
                            <span v-if="errors.account_number" class="text-danger">You can't add arabic letters or any spaces/symbols!</span>
                        </div>
                        <div class="form-group">
                            <label>IBAN</label>
                            <input placeholder="IBAN" class="form-control form-control-solid h-auto py-5 px-6" v-model="bankDetails.iban_number" @input="isLetter1($event,'iban_number')">
                            <span v-if="errors.iban_number" class="text-danger">You can't add arabic letters or any spaces/symbols!</span>
                        </div>
                        <div class="form-group">
                            <label>Swift(Optional)</label>
                            <input placeholder="Swift(Optional)" class="form-control form-control-solid h-auto py-5 px-6" v-model="bankDetails.swift_number" @input="isLetter($event,'swift_number')">
                            <span v-if="errors.swift_number" class="text-danger">You can't add arabic letters or any symbols!</span>
                        </div>
                     </div>
                      <div class="col-md-6">
                        <div class="form-group">
                            <label>Bank Name</label>
                            <input placeholder="Bank Name" class="form-control form-control-solid h-auto py-5 px-6" v-model="bankDetails.bank_name"  @input="isLetter($event,'bank_name')">
                             <p v-if="!bankDetails.bank_name && !fetchBank" style="color:red;">Bank name is required</p>
                             <span v-if="errors.bank_name" class="text-danger">You can't add arabic letters or any symbols!</span>
                        </div>
                        <!-- <div class="form-group">
                            <label>Country</label>
                            <input placeholder="Country" class="form-control form-control-solid h-auto py-5 px-6" v-model="bankDetails.bank_country"  @input="isLetter($event,'bank_country')">
                            <span v-if="errors.bank_country" class="text-danger">You can't add arabic letters or any symbols!</span>
                        </div> -->
                        <div class="form-group" label="Branch">
                            <label>Branch</label>
                            <input placeholder="Branch" class="form-control form-control-solid h-auto py-5 px-6" v-model="bankDetails.bank_branch"  @input="isLetter($event,'bank_branch')">
                             <p v-if="!bankDetails.bank_branch && !fetchBank" style="color:red;">Branch code is required</p>
                             <span v-if="errors.bank_branch" class="text-danger">You can't add arabic letters or any symbols!</span>
                        </div>
                     </div>
                   </div>
                </div>
                <div class="input-group btns">
                     <button type="button" class="cancel" @click='setSelectedtab("User Details")'>Back</button>
                     <button type="submit" class="save">Save</button>
                </div>
              </form>
             </div>
          </div>

          </div>
          <div class="data_grid mt-20" v-if="selected_tab == 'affliateManagerDetails'">
             <div class="table-div" :class="selected_tab == 'affliateManagerDetails' ? 'affliateManagerDetails' : ''">
                <table class="table table-bordered affiliateData">
                <thead></thead>
                <tbody>
                  <tr>
                    <td>Name</td>
                    <td>{{ affiliateDetails.name }}</td>
                  </tr>
                  <tr>
                    <td>Email ID</td>
                    <td>{{ affiliateDetails.email }}</td>
                  </tr>
                  <tr>
                    <td>Whatsapp Number</td>
                    <td>{{ affiliateDetails.phone }}</td>
                  </tr>
                </tbody>
              </table>
             </div>
          </div>
          <div class="data_grid mt-20" v-if="selected_tab == 'referralDetails'">
              <div class="table-div" :class="selected_tab == 'referralDetails' ? 'referralDetails' : ''"> 
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-6">
                        <div role="alert" v-if="successCopy" :class="{ 'show': successCopy }" class="alert fade alert-success d-flex align-items-center pl-3 alert-dismissible fade show" style="height:36px;margin-bottom:10px;">
                          <span>Referral Code Copied</span>
                          <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="successCopy = false">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>  
                    </div>
                    <div class="col-md-6"></div>
                  </div>
                </div>   
                <table class="table table-bordered referralData">
                <thead></thead>
                <tbody>
                  <tr>
                    <td>Your Referral Code </td>
                    <td v-if="referralDetails.referral_code" class="text-center"># <span id="copy_referral_code">{{ referralDetails.referral_code }}</span>
                      <a href="#" v-on:click="copyReferralCode()"><i class="fa fa-copy" style="color: #718096;margin-left: 11px;"></i></a>
                    </td>
                    <td v-else class="text-center"></td>
                  </tr>
                  <tr>
                    <td>Total No of referred people </td>
                    <td class="text-center">{{ referralDetails.total_referred_people}} </td>
                  </tr>
                  <tr>
                    <td>Total Payout</td>
                    <td class="text-center">AED {{ referralDetails.total_payout}} </td>
                  </tr> 
                </tbody>
              </table>
             </div>
             <div class="table-div">
              
                <table class="table table-bordered" style="width:100% !important;">
                <thead style="background-color: #FFF2F2;color: #686a6e;font-weight: 600;">
                  <tr>
                    <th>Referred Name</th>
                    <th class="text-center">Conversions</th>
                    <th>Payout</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="referralData.length > 0">
                    <tr v-if="referralData.length > 0" v-for="(data,index) in referralData" :key="index">
                      <td>{{ data.referred_name }}</td>
                      <td class="text-center" style="float: left;">{{ data.conversions }}</td>
                      <td>${{ data.payout }}</td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr>
                      <td class="text-center" colspan="3"> 
                        <div class="d-flex mt-3 justify-content-center alert alert-success"><b>No Records</b></div>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
             </div>
          </div>
          <div class="data_grid mt-20" v-if="selected_tab == 'changePassword'">
             <div class="table-div" :class="selected_tab == 'changePassword' ? 'changePassword' : ''">
               <b-form class="form" @submit.stop.prevent="changeUserPassword">
                <div class="col-md-12">
                  <div class="row">
                     <div class="col-md-6">
                          <div role="alert" v-if="error && !success" :class="{ 'show': error }" class="alert fade alert-danger d-flex align-items-center pl-3" style="height:36px;margin-bottom:10px;">
                              <span>{{ this.errorChangePasswordMsg }}</span>
                          </div>
                          <div role="alert" v-if="success" :class="{ 'show': success }" class="alert fade alert-success d-flex align-items-center pl-3" style="height:36px;margin-bottom:10px;">
                                <span>{{ this.successMsg }}</span>
                          </div>
                        <b-form-group id="example-input-group-1" label="Current Password" label-for="example-input-1">
                          <b-form-input placeholder="Current Password" class="form-control-solid h-auto py-5 px-6" id="example-input-1" name="example-input-1" aria-describedby="input-1-live-feedback" type="text" v-if="showPassword1" v-model="$v.form.current_password.$model">
                          </b-form-input>
                          <b-form-input placeholder="Current Password" class="form-control-solid h-auto py-5 px-6" id="example-input-1" name="example-input-1" aria-describedby="input-1-live-feedback" v-else type="password" v-model="$v.form.current_password.$model">
                          </b-form-input>
                          <a @click="toggleShow1" class="password-eye"><i class="fas" :class="{ 'fa-eye-slash': showPassword1, 'fa-eye': !showPassword1 }"></i></a>
                          <div v-if="submitted && $v.form.retype_password.$error" class="validation-error-msg">
                            <p v-if="!$v.form.current_password.required" style="color:red;">Current Password is required</p>
                          </div>
                        </b-form-group>
                        <b-form-group id="example-input-group-1" label="New Password" label-for="example-input-1">
                          <b-form-input placeholder="New Password" class="form-control-solid h-auto py-5 px-6" id="example-input-1" name="example-input-1" aria-describedby="input-1-live-feedback" type="text" v-if="showPassword2" v-model="$v.form.password.$model">
                          </b-form-input>
                          <b-form-input placeholder="New Password" class="form-control-solid h-auto py-5 px-6" id="example-input-1" name="example-input-1" aria-describedby="input-1-live-feedback" v-else type="password" v-model="$v.form.password.$model">
                          </b-form-input>
                          <a @click="toggleShow2" class="password-eye"><i class="fas" :class="{ 'fa-eye-slash': showPassword2, 'fa-eye': !showPassword2 }"></i></a>
                            <div v-if="submitted && $v.form.password.$error" class="validation-error-msg">
                              <p v-if="!$v.form.password.required" style="color:red;position: absolute;left: 15px;margin-top: 25px;">Passwords is required</p>
                              <p v-else-if="!$v.form.password.minLength" style="color:red;position: absolute;left: 15px;margin-top: 25px;">Passwords must be atleast 8 digits</p>
                              <p v-else-if="form.password && !$v.form.password.valid" style="color:red;position: absolute;left: 15px;margin-top: 25px;">Password must  contains atleast 1 uppercase, 1 lowercase, 1 number and 1 Special Character</p>
                              <p v-else-if="form.password && !$v.form.password.maxLength" style="color:red;position: absolute;left: 15px;margin-top: 25px;">Password should not greater 16 characters</p>
                            </div>
                        </b-form-group>
                        <b-form-group id="example-input-group-1" label="Confirm Password" label-for="example-input-1">
                          <b-form-input placeholder="Confirm Password" class="form-control-solid h-auto py-5 px-6" id="example-input-1" name="example-input-1" type="text" v-if="showPassword3" v-model="$v.form.retype_password.$model">
                          </b-form-input>
                          <b-form-input placeholder="Confirm Password" class="form-control-solid h-auto py-5 px-6" id="example-input-1" name="example-input-1" v-else type="password" v-model="$v.form.retype_password.$model">
                          </b-form-input>
                          <a @click="toggleShow3" class="password-eye"><i class="fas" :class="{ 'fa-eye-slash': showPassword3, 'fa-eye': !showPassword3 }"></i></a>
                          <div v-if="submitted && $v.form.retype_password.$error" class="validation-error-msg">
                            <p v-if="!$v.form.retype_password.required" style="color:red;position: absolute;left: 5px;margin-top: -16px;">Confirm password is required</p>
                            <p v-else-if="!$v.form.retype_password.sameAsPassword" style="color:red;position: absolute;left: 5px;margin-top: -16px;">Entered passwords do not match</p>
                          </div>
                        </b-form-group>
                     </div>
                  </div>
                </div>
                <div class="input-group btns">
                  <button type="button" class="cancel">Cancel</button>
                  <button type="submit" class="save">Save</button>
                </div>
              </b-form>
             </div>
          </div>
        </div>
        </div>
      </div>
	</div>
  </div>
</template>
<script>
import Vue from "vue";
import ApiService from "../../core/services/api.service";
import { mapGetters } from "vuex";
import Loader from "@/view/content/Loader.vue";
import TabLoader from "@/view/content/TabLoader.vue";
import Multiselect from "vue-multiselect";
import VueCountryCode from "vue-country-code-select";
Vue.use(VueCountryCode);
import { validationMixin } from "vuelidate";
import { minLength, required,sameAs,maxLength } from "vuelidate/lib/validators";
import cities from "../../core/cities.json";
import countries from "../../core/CountryCodes.json";
import TermsAndCondition from '../components/profile/terms-and-condition.vue'
import VueHtml2pdf from 'vue-html2pdf'
export default {
  mixins: [validationMixin],
  name: "profile",
  components: { Multiselect,ApiService , Loader,TabLoader,VueCountryCode, TermsAndCondition, VueHtml2pdf },
  data() {
    return {
      modalShow :false,
      fullAddress : null,
      idProofInput : null,
      fileUrl:'',
      successCopy : false,
      showPassword1: false,
      showPassword2: false,
      showPassword3: false,
      submitted : false,
      lang: "en",
      affiliate_id: null,
      selected_tab: "User Details",
      is_loading: false,
      is_bank_loading: false,
      successMsg : null,
      success :false,
      successUser : false,
      successBank : false,
      errorUser : false,
      error : false,
      errorChangePasswordMsg : null,
      errorBank : false,
      errorBankMsg : null,
      affiliateDetails : [],
      referralDetails : [],
      referralData : [],
      countryList : countries,
      fileInObj :false,
      cityList: [],
      selectedType : null,
      selectedExpType : null,
      selectedExpno : null,
      selectedCity : null,
      selectedCountry:null,
      dialCode : null,
      last_refreshed: localStorage.getItem("lastSeen")
        ? new Date(localStorage.getItem("lastSeen"))
        : new Date(),
      form: {
        current_password : "",
        password: "",
        retype_password: "",
      },
      bankDetails :{
        beneficiary_name :'',
        bank_name: '',
        account_number: '',
        bank_branch : '',
        swift_number: '',
        iban_number : '',
        bank_country: ''
      },
      errors :{
        beneficiary_name :false,
        bank_name: false,
        account_number: false,
        bank_branch : false,
        swift_number: false,
        iban_number : false,
        bank_country: false
      },
      fetchUser :true,
      fetchBank :true,
      userDetails : {
         company : null,
         type : null,
         email : null,
         phone : null,
         address1 :null,
         city : null,
         zipcode : null,
         country : null,
         experience_type : null,
         experience_no : null 
      },
      isLoading:false,
      idProofInput: null,
      termsFileUpload: {},
      is_user_loading:false
    };
  },
  validations: {
    form: {
      current_password: {
        required,
      },
      password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(16),
        valid: function(value) {
          const containsUppercase = /[A-Z]/.test(value)
          const containsLowercase = /[a-z]/.test(value)
          const containsNumber = /[0-9]/.test(value)
          const containsSpecial = /[#?!@$%^&*-]/.test(value)
          return containsUppercase && containsLowercase && containsNumber && containsSpecial
        },
      },
      retype_password: {
        required,
        sameAsPassword:sameAs('password'),
      },
    },
  },
  mounted() {
    this.affiliate_id = window.localStorage.getItem("affiliate_id");
    //this.getReferralData();
    //this.getAffiliateDetails();
    this.getUserDetails();
    this.userBankDetails();
    setInterval(() => {
      this.$eventBus.$emit('setNotification');
    }, 60000)
  },
  methods: {
    setSelectedtab(tab){
      this.selected_tab = tab
    },
    isLetter(e,type) {
      var letters = /^[A-Za-z0-9 ]*$/;
      if(e.target.value.match(letters)) {
        this.errors[type] = false;   
        this.error=false;
        return true;
      } else {     
        this.errors[type] = true;   
        this.error=true;
        // this.bankDetails[type]=''
      };
    },
    clearFileFun(){
      this.termsFileUpload=''
      this.fileInObj=false
    },
    isLetter1(e,type) {
      var letters = /^[A-Za-z0-9]*$/;
      if(e.target.value.match(letters)) {
        this.errors[type] = false;   
        this.error=false;
        return true;
      } else {     
        this.errors[type] = true;   
        this.error=true;
        // this.bankDetails[type]=''
      };
    },
    onProgress(e){
      this.is_loading = true;
      if(e == 100){
        this.is_loading = false;
      }
    },
    handleOnUploadFile(e){
    this.fileInObj = true
      this.termsFileUpload = e.target.files[0]
    },
    handleOnTerms(){
      this.$refs.html2Pdf.generatePdf();
      // window.open('/media/terms-and-condition/terms-and-condition.pdf');
    },
    toggleShow1() {
      this.showPassword1 = !this.showPassword1;
    },
    toggleShow2() {
      this.showPassword2 = !this.showPassword2;
    },
    toggleShow3() {
      this.showPassword3 = !this.showPassword3;
    },
    copyReferralCode(){
            var range = document.createRange();
            let containerNode = document.getElementById('copy_referral_code'); //// this part
            range.selectNode(containerNode); //// this part
            window.getSelection().removeAllRanges();
            window.getSelection().addRange(range);
            document.execCommand("copy");
            window.getSelection().removeAllRanges();
            this.successCopy = true;
    },
    onSelect({name, iso2, dialCode}) {
    },
    showModal() {
        this.isModalVisible = true;
    },
    closeModal() {
        this.isModalVisible = false;
    },
    resetForm() {
      this.form = {
        email: null,
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    changeUserPassword() {
      this.submitted = true
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return false;
      }
      let dataToPost = {
        affiliate_id: this.affiliate_id,
        old_password: this.$v.form.current_password.$model,
        new_password : this.$v.form.password.$model,
      };
      ApiService.post("/change-password", dataToPost).then((response) => {
          if(response.data.result == true){
            this.success = true;
            this.successMsg = response.data.message;
            // setTimeout(()=>{
                location.reload();
            // },3000);
          }else{
            this.error = true;
            this.errorChangePasswordMsg = response.data.message;
          }
      }).catch((error) => {
        this.error = true;
        this.errorChangePasswordMsg = data.message;
          if(error.response.status == 400){
            localStorage.clear();
            localStorage.removeItem("affiliate_id");
            Swal.fire({
              imageUrl: "media/images/logout-emoji.png",
              imageWidth: 165,
              imageHeight: 123,
              title: "Your Session is expired.",
              timer: 1500,
              timerProgressBar: true,
              showConfirmButton: false,
            })
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          }
          throw new Error(`[KT] ApiService ${error}`);
      });
    },
    getAffiliateDetails(){
      let dataToPost = {
        affiliate_id: this.affiliate_id,
      };
      ApiService.post("/affiliate-detail", dataToPost).then((response) => {
          if(response.data.result == true){
            this.affiliateDetails = response.data.data;
          }
      }).catch(error => {
          if(error.response.status == 400){
            localStorage.clear();
            localStorage.removeItem("affiliate_id");
            Swal.fire({
              imageUrl: "media/images/logout-emoji.png",
              imageWidth: 165,
              imageHeight: 123,
              title: "Your Session is expired.",
              timer: 1500,
              timerProgressBar: true,
              showConfirmButton: false,
            })
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          }
          throw new Error(`[KT] ApiService ${error}`);
        });
    },
    getReferralData(){
      let dataToPost = {
        affiliate_id: this.affiliate_id,
      };
      ApiService.post("/referral-detail", dataToPost).then((response) => {
          if(response.data.result == true){
            this.referralDetails = response.data.data
            this.referralData = response.data.data.referral;
          }
      }).catch(error => {
          if(error.response.status == 400){
            localStorage.clear();
            localStorage.removeItem("affiliate_id");
            Swal.fire({
              imageUrl: "media/images/logout-emoji.png",
              imageWidth: 165,
              imageHeight: 123,
              title: "Your Session is expired.",
              timer: 1500,
              timerProgressBar: true,
              showConfirmButton: false,
            })
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          }
          throw new Error(`[KT] ApiService ${error}`);
        });
    },
    getUserDetails(){
      let dataToPost = {
        affiliate_id: this.affiliate_id,
      };
      ApiService.post("/user-detail", dataToPost).then((response) => {
          if(response.data.result == true){
            this.fetchUser = false;
            this.userDetails = response.data.data;
            let selectedCountry = response.data.data.country;
            this.cityList = cities.filter(function (city) {if (city.country === selectedCountry) { return city }});
            let arr = [this.userDetails.address1, this.userDetails.city, this.userDetails.country];

            this.fullAddress = arr.filter(item => item).join(', '); 
            this.idProofInput = this.userDetails.id_proof == 'null'  ? '' : this.userDetails.id_proof;
            if(!this.userDetails.id_proof == 'null'){
              this.fileInObj = true
            }
            this.$eventBus.$emit('setUserDetail',this.userDetails);
          }
      }).catch(error => {
          if(error.response.status == 400){
            localStorage.clear();
            localStorage.removeItem("affiliate_id");
            Swal.fire({
              imageUrl: "media/images/logout-emoji.png",
              imageWidth: 165,
              imageHeight: 123,
              title: "Your Session is expired.",
              timer: 1500,
              timerProgressBar: true,
              showConfirmButton: false,
            })
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          }
          throw new Error(`[KT] ApiService ${error}`);
        });
    },
    getCityList(){
      let selectedCountry = this.userDetails.country;
      this.cityList = cities.filter(function (city) {if (city.country === selectedCountry) { return city }});
      this.cityList.sort((a,b) => { return a.name.localeCompare(b.name)})
    },
    updateUserDetails(){
        this.submitted = true;
        this.is_user_loading = true;
        let dataToPost = {
          affiliate_id: this.affiliate_id,
          company : this.userDetails.company,
          email : this.userDetails.email,
          type : this.userDetails.type,
          phone : this.userDetails.phone,
          dialCode : this.dialCode,
          address1 : this.userDetails.address1,
          city : this.userDetails.city,
          zipcode : this.userDetails.zipcode,
          country : this.userDetails.country,
          experience_no : this.userDetails.experience_no,
          experience_type : this.userDetails.experience_type,
        };

        ApiService.post("/edit-user-detail", dataToPost).then((response) => {
          if(response.data.result == true){
            this.successUser = true;
            this.getUserDetails();
          }else{
            this.errorUser = true;
            this.errorUserMsg = response.data.message;
          }
        }).catch(() => {
          this.isLoading = false
          if(error.response.status == 400){
            localStorage.clear();
            localStorage.removeItem("affiliate_id");
            Swal.fire({
              imageUrl: "media/images/logout-emoji.png",
              imageWidth: 165,
              imageHeight: 123,
              title: "Your Session is expired.",
              timer: 1500,
              timerProgressBar: true,
              showConfirmButton: false,
            })
            this.is_bank_loading = false;

            setTimeout(() => {
              window.location.reload();
            }, 1500);
          }
          throw new Error(`[KT] ApiService ${error}`);
        }).finally(()=>{
          this.is_user_loading = false;

        })

        // let formData = new FormData();
        // formData.append('affiliate_id', this.affiliate_id);
        // formData.append('id_proof', this.idProofInput);
        // formData.append('terms_and_conditions',this.termsFileUpload);
        // ApiService.post("/invoice/approval",formData).then((response) => {      
        //     console.log(response,"response");;
        // }).catch(error => {
        //   if(error.response.status == 400){
        //     localStorage.clear();
        //     localStorage.removeItem("affiliate_id");
        //     Swal.fire({
        //       imageUrl: "media/images/logout-emoji.png",
        //       imageWidth: 165,
        //       imageHeight: 123,
        //       title: "Your Session is expired.",
        //       timer: 1500,
        //       timerProgressBar: true,
        //       showConfirmButton: false,
        //     })
        //     setTimeout(() => {
        //       window.location.reload();
        //     }, 1500);
        //   }
        //   throw new Error(`[KT] ApiService ${error}`);
        // });
    },
    userBankDetails(){
      let dataToPost = {
        affiliate_id: this.affiliate_id,
      };
      ApiService.post("/bank-detail", dataToPost).then((response) => {
          if(response.data.result == true){
            this.bankDetails = response.data.data;
          }
      }).catch(error => {
          if(error.response.status == 400){
            localStorage.clear();
            localStorage.removeItem("affiliate_id");
            Swal.fire({
              imageUrl: "media/images/logout-emoji.png",
              imageWidth: 165,
              imageHeight: 123,
              title: "Your Session is expired.",
              timer: 1500,
              timerProgressBar: true,
              showConfirmButton: false,
            })
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          }
          throw new Error(`[KT] ApiService ${error}`);
        });
    },
    updateBankDetails(){

        this.submitted = true;
        this.is_bank_loading = true;
        let dataToPost = {
          affiliate_id: this.affiliate_id,
          beneficiary_name : this.bankDetails.beneficiary_name,
          bank_name: this.bankDetails.bank_name,
          account_number: this.bankDetails.account_number,
          bank_branch : this.bankDetails.bank_branch ,
          swift_number: this.bankDetails.swift_number,
          iban_number : this.bankDetails.iban_number,
          bank_country: this.bankDetails.bank_country
        };
        ApiService.post("/edit-bank-detail", dataToPost).then((response) => {
          if(response.data.result == true){
            this.successBank = true;
            this.userBankDetails()
          }else{
            this.errorBank = true;
            this.errorBankMsg = response.data.message;
          }
        }).catch(error => {
          if(error.response.status == 400){
            localStorage.clear();
            localStorage.removeItem("affiliate_id");
            Swal.fire({
              imageUrl: "media/images/logout-emoji.png",
              imageWidth: 165,
              imageHeight: 123,
              title: "Your Session is expired.",
              timer: 1500,
              timerProgressBar: true,
              showConfirmButton: false,
            })
            // setTimeout(() => {
            //   window.location.reload();
            // }, 1500);\
          }
          throw new Error(`[KT] ApiService ${error}`);
        })
        .finally(()=>{
          this.is_bank_loading=false
        })
    },
    getFullAddress(){
      let arr=[this.userDetails.address1 ,  this.userDetails.city  , this.userDetails.country]
      this.fullAddress = arr.filter(item=>item).join(', ');
      this.modalShow  = false;
    },
    validateUserDetails(){
      if(this.userDetails.company != '' && this.userDetails.phone != '' && this.userDetails.address1 != '' && this.userDetails.city != ''){
        this.submitted = true;
        this.updateUserDetails();
      }else{
        this.submitted = false;
        this.is_user_loading = false;
      }
    },
    validateBankDetails(){
      // if(!this.error && this.bankDetails.account_number && this.bankDetails.bank_name && this.bankDetails.bank_branch && !this.errors.beneficiary_name && !this.errors.bank_name && !this.errors.account_number &&
      // !this.errors.bank_branch && !this.errors.swift_number && !this.errors.iban_number && !this.errors.bank_country){
      if(!this.error && this.bankDetails.account_number && this.bankDetails.bank_name && this.bankDetails.bank_branch && !this.errors.beneficiary_name && !this.errors.bank_name && !this.errors.account_number &&
      !this.errors.bank_branch && !this.errors.swift_number && !this.errors.iban_number){
        if(this.userDetails.company && this.userDetails.phone && this.userDetails.address1 && this.userDetails.city){
          this.submitted = true;
          this.updateBankDetails();
        }
        else{
          this.setSelectedtab('User Details')
        }
      }else{
        this.submitted = false;
        this.fetchBank = false;
      }
    },
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    },
  },
};
</script>

<style lang="scss">
.p-upload-file{
  text-align: right;
}
.p-upload-file input{
  height: 0px;
  width: 0px;
}
.p-upload-file-btn{
    border: 1px solid #E4E6EF;
    margin-bottom: 0px !important;
    text-align: center;
    width: 200px;
    border-radius: 5px;
    font-size: 15px !important;
    font-weight: 600 !important;
    padding: 5px;
}
.p-btn-terms{
  color: #FF6633;
  width: auto !important; 
  // padding: 10px !important;
  border: none !important;
  background: none !important;
  font-size: 14px;
  font-weight: 600;
  white-space: pre-wrap;
}
.p-file-input .v-input__prepend-outer{
  display: none;
}
.p-file-input .v-input__control{
  max-width: 200px;
}
.h-notify-count{
  background: #f56160;
  color: #fff;
  border-radius: 10px;
  width: 25px;
  font-size: 12px;
}
.btn-mobile-profile ul{
  width: auto !important;
}
.col-xl-12.profileInfo.pt-0 {
    margin-top: 0px;
    position: relative;
    // z-index: 99;
    z-index: unset;
}

.offer-container{
    text-align: center !important;
    min-width: fit-content !important;
}
.offer-container .dropdown-item:active{
  background-color: #EBEDF3 !important;
}
.offer-link:hover{
  background-color: transparent !important;
}
.offer-sort-btn {
    font-size: 13px !important;
    font-weight: normal !important;
    line-height: normal !important;
    color: inherit !important;
    background-color: transparent !important;
}
.offer-text{
    margin-right: 10px !important;
}
.offer-link{
    padding: 0.25rem 0.25rem !important;
}
.offer-link:hover{
    color: #0f2753 !important;
}
.btn-div{
	display:flex;
	align-items: center;
	justify-content: space-between;
	.update-btn{}
	.export-btn{
		button{
			background: linear-gradient(rgb(3, 3, 53),rgb(11, 28, 102));
			border:none;
			border-radius: 6px;
			font-size:13px;
			color:#fff;
			padding:7px 15px;
		}
	}

}
.imageClearDiv{
    display: flex;
    justify-content: end;
    margin-right: -10px;
}
.invoiceLabels {width: 30%;}
.invoiceLabels ul li {
    padding: 7.5px 7px;
    margin-right: 5px;
    cursor: pointer;
    border-top: 2px solid #949494;
    transition: 0.2s ease-in-out;
}
.invoiceLabels ul li:last-child {
    border-bottom: 2px solid #949494;
}
.invoiceLabels ul li:hover {
    background: #d5d5d5;
}
.invoiceLabels ul li.active{
  background: #383b5d;
  color: #fff;
}
.container-fluid{
	padding:0px !important;
}

.table-div{
  // max-height: 420px;
  // overflow: hidden;
  // overflow-y:auto;
  table{
	  thead{
		  padding:0px;
	  }
    tbody{
      tr{
        .long-column-bottom{
          min-width: 180px;
          font-weight: bold;
          vertical-align: middle;
        }
        .long-column{
          min-width: 180px;
          // vertical-align: bottom;
          h3{
            font-weight: bold;
            margin: 0px;
            font-size: 19px;
          }
        }
        td{
			
        }
      }
    }
  }
}
@media screen and (max-width: 768) {
	 .table {
		 border: none;
	}
}
@media screen and (max-width: 767.9px) {
	.table thead th {
		padding:5px 10px;
		
	 }
	.table thead th span {
		font-size:10px;
		white-space:nowrap;
	 }
	 .table tbody tr td{
		//  word-break: break-all;
	 }
   .p-upload-file{
     display: contents;
   }
   .p-upload-file-btn{
     width: 145px;
     display: flex;
     align-items: center;
     justify-content: center;
   }
   .p-btn-terms{
     font-size: 13px;
     width: 165px !important;
   }
}
 @media screen and (max-width: 768) {
	 .table tr {
		 border-bottom: 3px solid #ddd;
		 display: block;
		 margin-bottom: 0.625em;
	}
}
 @media screen and (max-width: 768) {
	 .table thead {
		 border: none;
		 clip: rect(0 0 0 0);
		 height: 1px;
		 margin: -1px;
		 overflow: hidden;
		 padding: 0;
		 position: absolute;
		 width: 1px;
	}
}
 @media only screen and (max-width: 768px) {
   .table-coupon thead{
     display: none;
   }
   .table-coupon tr{
     border-bottom: 15px solid #f0f4f7;
   }
	 .table-coupon td {
		 border-bottom: 1px solid #ddd;
		 display: flex;
     display: -webkit-flex;
     display: -ms-flexbox;
		 font-size: 0.8em;
		 text-align: right;
	}
	 .table-coupon td::before {
		 content: attr(data-label);
		 display: block;
		 width: 100%;
		 text-align: left;
		 font-weight: bold;
		 text-transform: uppercase;
	}
	 .table-coupon td:last-child {
		 border-bottom: 0;
	}
}
.body-content{
  position: unset !important;
  width:100%;
}
#kt_content{
  min-height: 100vh !important;
  padding-top: 50px;
  height:  unset !important;
}
.crone-alert{
  width: 100%;
  background-color: #000;
  color: #fff;
  padding: 5px 5px 5px 10px;
  border-radius: 5px;
  margin: 10px 0px;
  font-weight: 600;
}
.filterButtonGroup {
  display: flex;
  width: 100%;
}
.filterButtonGroup button {
  margin-right: 5px;
}
.badge {
  color: white;
  border-radius: 5px;
}
.new-tag-bg {
  background: #0253b0;
  border-color: #0253b0;
}
.comfirmed-tag-bg,
.approved-tag-bg {
  background: #00c778;
  border-color: #00c778;
}
.cancelled-tag-bg {
  background: #707070;
  border-color: #707070;
}
.export_file {
  float: right;
  cursor: pointer;
}
.s_date {
  // margin-right: 4%;
  cursor: pointer;
}
.select {
  color: #000;
  font-weight: 700;
}
//   .plan-boxes {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//   }
// }

// @media only screen and (max-width: 600px) {
//   .plan-box {
//     height: 40%;
//     position: relative;
//   }
//   .logo{
//     width: 80% !important;
//   }
// }
.modal-btn {
  width: 100%;
  color: white;
  background: #000;
  border-radius: 0px;
}
.tabs {
  display: flex;
  text-align: center;
  font-weight: 600;
  border-bottom: 1px solid #e8e1e1;
  .tab {
    cursor: pointer;
  }
  .active {
    border-bottom: 1.5px solid black;
  }
}
@media only screen and (max-width: 450px) {
  .tabs {
    display: table !important;
    margin: auto;
  }
}

// for destop
@media only screen and (min-width: 1200px) {
  .plan-boxes {
    span:first-of-type {
      margin-bottom: 7%;
    }
  }
  .plan-box {
    background-color: #ffffff;
    min-height: 200px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;

    span:first-of-type {
      display: block;
      text-transform: uppercase;
      color: #000;
      font-weight: bold;
      font-size: 16px;
    }

    h1 {
      font-weight: bold;
      color: #000;
      font-size: 4rem;
      margin: 10px 0;
    }

    span:last-of-type {
      display: block;
      text-transform: capitalize;
      color: #000;
      font-weight: bold;
      font-size: 16px;
    }
  }
}

@media only screen and (min-width: 1200px) {
  .last_refreshed {
    color: black !important;
    // margin-top: 220px;
    icon {
      color: white;
    }
    span {
      margin-left: 1rem;
    }
  }
  .table-div.referralDetails + div.table-div table thead th:last-child {
    text-align: center !important;
    padding-right: 70px;
}

.table-div.referralDetails + div.table-div table tbody td:last-child {
    text-align: center !important;
    padding-right: 70px;
}
}
.ddd {
  margin-top: -2.5rem !important;
}
@media only screen and (max-width: 1200px) {
  .last_refreshed {
    color: #19248d;
    margin-top: 0px !important;
    span {
      margin-left: 1rem;
    }
  }
}
@media only screen and (max-width: 1600px) {
  .last_seen {
    color: white;
    float: right;
    span {
      margin-left: 1rem;
    }
  }
}

//for screens bigger then mobile
@media only screen and (min-width: 1600px) {
  .last_seen {
    color: black;
    float: right;
    span {
      margin-left: 1rem;
    }
  }
}

// for mobile
@media only screen and (max-width: 1200px) {
  .offer-text{
      margin-right: 0px !important;
  }
  .plan-boxes {
    margin-bottom: 5%;
    display: inline;
    span:last-of-type {
      float: right;
    }
  }
  .plan-box {
    background-color: #ffffff;
    width: 45%;
    min-height: 100px;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;

    span:first-of-type {
      display: block;
      text-transform: uppercase;
      color: #000;
      font-weight: bold;
      font-size: 12px;
    }

    h1 {
      font-weight: bold;
      color: #000;
      font-size: 2rem;
      margin: 10px 0;
    }

    span:last-of-type {
      display: block;
      text-transform: capitalize;
      color: #000;
      font-weight: bold;
      font-size: 12px;
    }
  }
#kt_content .ddd {
    display: none;
}

.header-mobile-fixed .header-mobile {
    height: 350px !important;
}

#kt_content .container-fluid {
    margin-top: -240px;
    position: relative;
    z-index: 99;
}

.plan-box span:last-of-type {
    font-size: 16px;
}

.plan-box h1 {
    font-size: 3rem;
}

.btn-div.mt-5.mobile {
    display: flex !important;
    justify-content: space-between !important;
    width: 100%;
    padding: 0 10px 0 0;
}
.btn-div.mt-5.mobile button.btn.btn-secondary {
    background: transparent;
    border: 0;
    margin: 0;
    width: auto;
}

.dropdown.mobile.d-lg-none.d-block.p-2 {
    display: none !important;
}
}



.light_gray {
  color: rgb(181, 181, 181);
  padding:0px;
}
.toggle_buttons {
  border: 2px solid rgb(121, 121, 121) !important;
  border-radius: 5px !important;
  font-size: 1rem !important;
  font-weight: bold !important;
}
@media only screen and (max-width: 600px) {
  .toggle_buttons {
    width: 6rem !important;
    font-size: 0.8rem !important;
  }
}
@media only screen and (max-width: 450px) {
  .toggle_buttons {
    width: 7rem !important;
    height: 4rem !important;
    font-size: 0.85rem !important;
    margin: 0.1rem !important;
  }
}
@media only screen and (max-width: 363px) {
  .toggle_buttons {
    width: 6rem !important;
    height: 2.5rem !important;
    font-size: 0.7rem !important;
  }
}
@media only screen and (max-width: 305px) {
  .toggle_buttons {
    width: 4rem !important;
    height: 3rem !important;
    font-size: 0.5rem !important;
  }
}
@media only screen and (max-width: 450px) {
  .table_res_head {
    display: none !important;
  }
}

#subparent .tab_buttons {
  border: none !important;
  border-radius: 5px !important;
  color: white;
  background-color: #FF6633 !important;
}
#parent .tab_buttons {
  border: none !important;
  border-radius: 5px !important;
  color: #000;
  background-color: #FF6633 !important;
}
.active_status {
  // background-color: #00c778;
	//   padding: 0.2rem 1.8rem;
  padding:2px 10px;
  border-radius: 0.3rem;
  width:100%;
  text-align:center;
}
.expired_status {
  // background-color: #f59253;
//   padding: 0.2rem 1.8rem;
  padding:3px 5px;
  border-radius: 0.3rem;
  width:100%;
  text-align:center;
} 
.status_coupon {
  color: rgb(163, 137, 137);
  border-radius: 0.3rem;
}
.modal-content{
  bottom: unset !important;
}

@media(max-width:767px){
  .bar span {
    font-size: 11px;
}  
}



// Changes

div#filter_modal___BV_modal_outer_ {
    z-index: 9999999 !important;
}

@media(min-width:1200px){

  p.last_refreshed {
    color: #000 !important;
    // margin-top: 110px !important;
    position: relative;
    z-index: 99;
    padding-left: 0;
    font-weight: inherit;
    font-size: 20px;
    margin-bottom: 8px;
    width: 70%;;
    margin-top:0 !important;
}

p.last_refreshed + .btn-div.mt-5 {
    position: relative;
    z-index: 999;
    padding-left: 0;
    margin-top: 0 !important;
    width: 70%;
}

p.last_refreshed + .btn-div.mt-5 .export-btn {
    display: none;
}

p.last_refreshed + .btn-div.mt-5 .update-btn button {
    font-size: 12px;
    padding: 6px 10px;
    border-radius: 6px;
}
.content-wrapper > div > .row {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: 0 !important;
}

.content-wrapper .plan-boxes {
    max-width: none !important;
    flex: none;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: -93px;
    position: relative;
    // z-index: 9999999;
    width: 80%;
    margin-left: 20%;
    margin-bottom: 17px;
}

.content-wrapper .plan-boxes + div {
    max-width: 100% !important;
    flex: none;
    position: relative;
    z-index: 9999999;
}

.plan-boxes span {
    min-height: auto;
    min-width: 140px;
    padding: 5px;
    margin-bottom: 0 !important;
}

.plan-box h1 {
    margin: 0;
    font-size: 30px;
}

span.plan-box:first-of-type {
    margin-right: 10px;
}

#kt_content > div > .container-fluid {
    max-width: 1185px;
    width: 100%;
}

.inf-table {
    padding-top: 0 !important;
}

.v-btn:before {
    display: none;
}

.v-btn-toggle > .v-btn.v-btn {
    opacity: 1 !important;
    height: 40px !important;
    border-width: 1px !important;
}

.inf-table p.table-head {
    font-size: 18px;
    color: #000;
}

.inf-table .tabs {
    border: 0;
}
.inf-table p.table-head span {
    font-size: 10px;
    position: relative;
    top: -10px;
    opacity: 0.3;
}
.data_grid {
    margin-top: 0 !important;
}
.table thead th, .table tbody td {
    border: 0 !important;
}

.table-div .table-bordered {
    border: 0 !important;
}
.modal .modal-header h4 {
    color: #000;
    font-weight: bold;
    font-size: 20px;
}

.modal .modal-header {
    border: 0;
    padding-bottom: 0;
}

div#filter_modal___BV_modal_body_ > .row > .col-12:first-child {
    color: #000;
    font-weight: bold;
}

div#filter_modal___BV_modal_body_ > .row label {
    font-weight: bold;
    color: #000;
}

div#filter_modal___BV_modal_body_ > .row > div input {
    color: #000;
}

.update-btn span {
    font-size: 11px;
    color: #000;
}

.update-btn span i {
    color: #000;
    font-size: 11px;
    margin-left: 5px;
    -webkit-transform: rotateY(160deg) rotate(12deg);
    transform: rotateY(160deg) rotate(12deg);
}
tr.offers {
    display: none;
}
.active_status {
    // background-color: #00c778;
    padding: 0px 15px 1px;
    width: auto;
    text-align: center;
    // color: #fff;
    line-height: normal;
    border-radius: 0;
    font-weight: normal;
}
.expired_status {
    padding: 0px 5px 1px;
    width: auto;
    text-align: center;
    // color: #fff;
    line-height: normal;
    border-radius: 0;
    font-weight: normal;
}
.table thead th {
    text-align: left !important;
    font-size: 12px !important;
    font-weight: normal;
} 
td.for-desk {
    width: 200px;
}
td.advertise {
    width: 250px;
}
.data_grid table {
    border: 0;
}
.data_grid table tr td {
    font-weight: bold;
}

.content-wrapper .plan-boxes {
    margin-bottom: 29px;
}

// div#filter_modal___BV_modal_body_ > .row > div {
//     margin-bottom: 24px;
// }
.v-card.v-sheet.theme--light .v-list-item.theme--light {
    padding-left: 0;
}
p.last_refreshed + .btn-div.mt-5 .update-btn button {
    display: block !important;
    margin-top: 15px;
}

.update-btn {
    display: flex;
    flex-direction: column-reverse;
}
div#modal-1___BV_modal_outer_ {
    z-index: 9999999 !important;
}
.table-div:first-child > table > tbody > tr {
    border-bottom: 15px solid #fff!important;
    background-color: #FFF2F2;
}

#kt_content > .d-flex.flex-column-fluid {
    position: relative;
    top: 100px;
}
}




form.form .btn.btn-dark.form-control {
    margin-top: 20px;
}
.form-group label {
    font-size: 13px !important;
    font-weight: bold !important;
    color: #000 !important;
}

footer#filter_modal___BV_modal_footer_ .modal-btn.btn-dark {
    border-radius: 6px;
}

@media(max-width:1200px){
  #kt_content .ddd {
    display: none;
}

.header-mobile-fixed .header-mobile {
    height: 350px !important;
}

#kt_content .container-fluid {
    margin-top: -240px;
    position: relative;
    z-index: 99;
}


.plan-boxes .plan-box {
    padding: 0 10px;
}

.plan-box span:last-of-type {
    font-size: 16px;
}

.plan-box h1 {
    font-size: 3rem;
}

.btn-div.mt-5.mobile {
    display: flex !important;
    justify-content: space-between !important;
    width: 100%;
    padding: 0 10px 0 0;
}
.btn-div.mt-5.mobile button.btn.btn-secondary {
    background: transparent;
    border: 0;
}

.dropdown.mobile.d-lg-none.d-block.p-2 {
    display: none !important;
}

.v-btn-toggle--group > .v-btn.v-btn {
    margin: 0 !important;
    padding: 0 !important;
    border: 0 !important;
    border-radius: 0 !important;
    min-width: auto!important;
    width: 80px !important;
    font-size: 13px !important;
    display: block !important;
    margin-right: 18px !important;
    border-bottom: 2px solid transparent !important;
    margin-right: 6px !important;
    padding-bottom: 5px;
    color: #8080808c !important;
}

#parent .tab_buttons {
    color:#151a4b !important;
    background-color: transparent !important;
    min-width: auto!important;
    width: 80px !important;
    font-size: 13px !important;
    border-bottom: 2px solid #FF6633 !important;
    border-radius: 0 !important;
    font-weight: bold !important;
}

.v-btn:before {
    display: none;
}

.v-btn-toggle--group {
    display: flex !important;
    flex-wrap: nowrap !important;
    overflow-x: auto;
    width: 270px !important;
    padding: 0 0;
    padding-bottom: 5px;
}

.v-btn__content {
    display: inline-block;
}

.modal-content {
    bottom: 0 !important;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.data_grid .alert.alert-primary {
    width: 90%;
    margin: 10px auto;
}

.table tbody tr td {
    font-weight: bold;
}

.table tbody tr.offers td {
    font-weight: normal !important;
    opacity: 0.8;
}

.active_status {
    // color: #fff;
    // padding: 0px 5px !important;
    width: auto;
}

.expired_status {
    // color: #fff;
    padding: 0px 3px;
}
#parent .toggle_buttons {
    height: 36px !important;
}

.table thead th {
    vertical-align: top;
    font-size: 13px !important;
}

header#filter_modal___BV_modal_header_ > h4 {
    font-size: 22px;
    color: #000;
    font-weight: bold;
}

div#filter_modal___BV_modal_body_ > .row .col-12:first-child {
    font-weight: bold;
    font-size: 14px;
    color: #000;
}

div#filter_modal___BV_modal_body_ > .row .col-12 label {
    font-weight: bold;
    font-size: 14px;
}
th.light_gray {
    text-align: left !important;
}

div#filter_modal___BV_modal_body_ > .row > div:nth-child(2) {
    padding-right: 0;
    -ms-flex: 0 0 42.666667%;
    flex: 0 0 45.666667%;
    max-width: 45.666667%;
}

div#filter_modal___BV_modal_body_ > .row > div:nth-child(3) {
    padding-left: 0;
    padding-right: 0;
    /*-ms-flex: 0 0 15.666667%;*/
    /*flex: 0 0 12.666667%;*/
    /*max-width: 12.666667%;*/
}

    div#filter_modal___BV_modal_body_ > .row > div:nth-child(4) {
        padding-right: 0;
        padding-left: 0;
    }
    div#filter_modal___BV_modal_body_ {
        padding-bottom: 0;
        padding-top: 0;
    }
    
    header#filter_modal___BV_modal_header_ {
        padding-bottom: 10px;
    }
    
    div#filter_modal___BV_modal_body_ > .row .col-12:first-child {
        padding-bottom: 0;
    }
    .plan-box {
        width: 47%;
    }
    .plan-box h1 {
      margin-top: 0;
    }

    .plan-box span:last-of-type {
        font-size: 15px;
        padding-bottom: 5px;
    }
    .table thead th, .table tbody td {
        border: 0 !important;
    }

    .table-div .table-bordered {
        border: 0 !important;
    }
    th.light_gray.desk {
       display: none;
    }

    td.for-desk {
      display: none;
    }
    .tabs {
    border: 0;
}
.inf-table {
    padding-top: 0 !important;
}

.tabs {
    padding-bottom: 0 !important;
}

.data_grid table.table {
    margin-top: 10px !important;
    border: 0;
}

#kt_content .container-fluid {
    margin-top: -288px;
}

.inf-table .tabs {
    padding-bottom: 0 !important;
}

.data_grid {
    margin-top: 20px !important;
}

div#filter_modal___BV_modal_body_ > .row > div {
    margin-bottom: 18px;
}
.modal {
      z-index: 999999999;
  }
  #kt_content .ddd {
      display: block;
      padding-left: 8px;
    }

    #kt_content .ddd p.last_refreshed {
        display: none;
    }

    #kt_content .ddd .export-btn {
        display: none;
    }

    #kt_content .ddd span {
        display: none;
    }
    #kt_content .ddd button.btn.btn-secondary {
      display: block !important;
      margin-top: 15px;
    }
    div#modal-1___BV_modal_content_ {
      height: 100vh;
      overflow-y: auto;
    }
  .header-mobile-fixed .header-mobile {
      height: 410px !important;
  }

  #kt_content .container-fluid {
      margin-top: -286px;
      position: relative;
      z-index: 99;
      width: 100%;
      padding-left: 1rem !important;
      padding-right: 1rem !important;
  }
div#modal-1___BV_modal_outer_ {
    position: static !important;
    height: 100%;
}

div#modal-1___BV_modal_outer_ .modal-dialog.modal-md {
    height: 100%;
    max-width: 100% !important;
    width: 100% !important;
    margin: 0 !important;
    pointer-events: auto !important;
    position: static !important;
}

div#modal-1___BV_modal_outer_ header {
    pointer-events: auto;
}
div#filter_modal___BV_modal_outer_ {
        position: static !important;
        height: 100%;
        width: 100%;
      }

      div#filter_modal___BV_modal_outer_ .modal-dialog.modal-md {
        max-width: 100% !important;
        width: 100% !important;
        height: 100% !important;
        z-index: 9999;
        display: flex;
        margin: 0;
        align-items: flex-end;
      }

      div#filter_modal___BV_modal_outer div#filter_modal___BV_modal_content {
        position: static;
        height: 90%;
        overflow-y: auto;
      }
      div#filter_modal___BV_modal_outer div#filter_modal___BV_modal_content footer {
        border: 0;
      }
      div#filter_modal___BV_modal_content_ {
    position: static;
    height: 90%;
    overflow-y: auto;
}
table.table.table-bordered.referralData {
    width: 100% !important;
}
table.table.table-bordered.affiliateData {
    width: 100% !important;
}

.input-group.btns button.save {
    min-width: 200px !important;
}
.table-div:first-child > table > tbody > tr {
    border-bottom: 15px solid #fff!important;
    background-color: #FFF2F2;
}
.input-group.btns button.save {
    width: 150px !important;
    min-width: auto !important;
}
div#address___BV_modal_outer_ {
    position: static !important;
    height: 100%;
    width: 100%;
}

div#address___BV_modal_outer_ .modal-dialog.modal-md {
    max-width: 100% !important;
    width: 100% !important;
    height: 100% !important;
    z-index: 9999;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
}

div#address___BV_modal_outer_ div#address___BV_modal_content_ {
    position: static;
    height: 90% !important;
    overflow-y: auto;
    transform: none !important;
}
div#address___BV_modal_outer_ button.btn.btn-primary {
    width: 150px !important;
    min-width: auto !important;
}
#parent .toggle_buttons {
    width: auto !important;
    margin-right: 30px !important;
}
#kt_content .container-fluid {
    margin-top: -275px !important;
}

.vue-country-select .dropdown-list {
    width: 250px !important;
}
}
.v-btn--plain:not(.v-btn--active):not(.v-btn--loading):not(:focus):not(:hover) .v-btn__content {
    opacity: 1 ;
}
div#modal-1___BV_modal_content_ button.close {
    font-size: 40px;
    line-height: normal;
    opacity: 1;
}
.v-btn-toggle--group > .v-btn.v-btn {
    width: auto !important;
}

.vue-country-select {
    border: 0 !important;
    height: 100%;
    border-radius: 0;
}

.vue-country-select .dropdown-arrow {
    display: none !important;
}

.vue-country-select .dropdown {
    border-right: 1px solid #bbb !important;
    padding: 0 !important;
    padding-right: 10px !important;
}

.vue-country-select .country-code {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    margin-left: 10px;
}
 .table-div.userdetails > form.form > .col-md-12 > .row > .col-md-6:first-child > .form-group:nth-last-child(2) > label + div{
    display: flex;
    align-items: center;
    background-color: #FFF2F2;
    border-radius: 0.85rem;
}

.vue-country-select {
    margin-left: 22px;
}

.vue-country-select {
    box-shadow: none !important;
}

.vue-country-select .dropdown-list {
    border: 0;
    top: 185%;
    box-shadow: 0 0 5px rgba(0,0,0,0.2);
    border-radius: 7px;
    left: -20px !important;
}

.vue-country-select .dropdown-list::-webkit-scrollbar{
  width:10px;
}
.data_grid {
    // overflow-y: initial;
}

.input-group.btns {
    justify-content: flex-end;
}
.input-group.btns button {
    border: 0;
    outline: none;
    color: #425466;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 162.02%;
}

.input-group.btns button.save {
    background: #FF6633;
    border-radius: 8px;
    color: #000;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 14px;
    width: 93px;
    height: 45px;
    min-width: 240px;
    margin-left: 40px;
}
.table-div form.form label {
    color: #718096 !important;
    font-weight: normal !important;
}

table.table.table-bordered.referralData {
    width: 50%;
    margin-top: 30px;
}
table.table.table-bordered.affiliateData {
    width: 50%;
    margin-top: 30px;
}
// .inf-table table.table.table-bordered tbody tr {
//     border-bottom: 1px solid #ccc;
// }


.inf-table table.table.table-bordered tbody tr td:nth-child(2) {
    text-align: right;
    padding-right: 70px !important;
   
}

.inf-table table.table.table-bordered tbody tr td:nth-child(1) {
    color: #718096;
    font-weight: normal;
}
.input-group.btns {
    padding: 0 10px;
}
a.password-eye {
    position: absolute !important;
    right: 25px;
    margin-top: -35px;
    cursor: pointer;
}
div > button.btn.btn-secondary {
    width: 23px;
    height: 22px;
    background: #FFF2F2;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    float: right;
    margin-top: -77px;
    margin-right: 20px;
}

button > i.fa.fa-edit {
    color: #4AAE8C;
}

div#address___BV_modal_outer_ div#address___BV_modal_content_ {
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    height: auto;
    padding: 0 10px;
}

div#address___BV_modal_outer_ button.btn.btn-primary {
    background: #FF6633;
    border-radius: 8px;
    color: #000;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 14px;
    width: 93px;
    height: 55px;
    min-width: 200px;
    margin-left: 40px;
    border: 0;
}

div#address___BV_modal_outer_ button.btn.btn-secondary {
    background: transparent;
    border: 0;
}

button.btn.pencil-btn.btn-secondary {
    margin: 0;
    float: none;
    padding: 5px;
    width: 30px;
    height: 30px;
    top: 10px;
    right: 5px;
    position: absolute; 
    background: transparent;
    border: 0;
}

button.btn.pencil-btn.btn-secondary:focus {
    background: transparent;
}

button.btn.pencil-btn.btn-secondary:hover {
    background: transparent !important;
        border: 0;
}
button.btn.pencil-btn.btn-secondary img {
    width: 20px;
    height: 20px;
}

.table-div.userdetails > form.form > .col-md-12 > .row > .col-md-6:first-child > .form-group:last-child > label + div {
    position: relative;
}
.validation-error-msg p {
    position: static !important;
    margin-top: 5px !important;
}

.table-div.referralDetails + .table-div table tr {
    border: 0 !important;
}

.table-div.referralDetails + .table-div table tr th {
    font-weight: bold;
}

.table-div.referralDetails + .table-div table tr td {
    font-weight: normal;
}

.table-div.referralDetails + .table-div table tr td:first-child {
    font-weight: bold !important;
}
.modal.fade .modal-dialog {
    -webkit-transform: none !important;
    transform: none !important;
}
div#address___BV_modal_outer_ footer {
    padding-top: 0 !important;
}

div#address___BV_modal_outer_ div#address___BV_modal_body_ {
    padding-bottom: 0 !important;
}

div#address___BV_modal_outer_ footer button {
    height: 50px !important;
    border-radius: 15px !important;
}
.data_grid .v-card.v-sheet.theme--light {
    box-shadow: none !important;
}
.inf-table table.table.table-bordered.affiliateData tbody tr td:nth-child(2) {
    text-align: left !important;
}
.inf-table table.table.table-bordered.referralData tbody tr td:nth-child(2) {
    text-align: left !important;
}
.table tbody tr td {
        vertical-align: middle;
}
#kt_content > .d-flex.flex-column-fluid {
    position: relative;
    top: 100px;
    margin-bottom: 100px;
}
div#address___BV_modal_outer_ div#address___BV_modal_backdrop_ {
    transition: all 0.15s linear;
}

body.modal-open {
    overflow: initial !important;
}
 .table-div.referralDetails + div.table-div table thead th:nth-child(2) {
    text-align: left !important;
}

div#address___BV_modal_outer_ {
    z-index: 99999 !important;
}

.swal2-container.swal2-center {
    z-index: 999999;
}
.swal2-styled.swal2-confirm {
    color: #FF6633 !important;
}
.alert.alert-success{
  background-color: #FF6633;
    border-color: #FF6633;
    color: #000;
}
.form-control.form-control-solid, .form-control.form-control-solid:focus{
  background-color: #FFF2F2 !important;
    border-color: #FFF2F2 !important;
}
</style>
